import React, { Component } from 'react';
import './App.css';
import ReactAvatarEditor from 'react-avatar-editor';
import firebase from './firebase';

import { TwitterIcon, FacebookShareButton, FacebookIcon, TwitterShareButton } from 'react-share';

export const uuid = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

async function postData(url = '', data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preview: null,
      src: undefined,
      stickerType: 'type1',
      inProgress: false,
      isDone: false,
    };
    this.onCrop = this.onCrop.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    this.setState({ preview: null });
  }

  onCrop(preview) {
    this.setState({ preview });
  }

  handleNewImage = (e) => {
    this.setState({ image: e.target.files[0] });
  };

  componentDidMount() {
    this.setState({
      size: window.innerWidth > 400 ? 400 : window.innerWidth * 0.8,
    });
  }

  render() {
    const { size, inProgress, isDone, outputImage, stickerType } = this.state;
    return (
      <>
        <nav>
          <a href='https://getmodemagic.com/'>
            <img className='logo' src={'https://getmodemagic.com/wp-content/uploads/2020/05/mm-logo-2x.png'}></img>
          </a>
        </nav>
        {!(inProgress || isDone) && (
          <div className='app-container'>
            <h3>Create your personalized (re)unite Avatar</h3>
            {size && (
              <div>
                <ReactAvatarEditor
                  ref={(ref) => {
                    this.editor = ref;
                  }}
                  width={size}
                  height={size}
                  image={this.state.image}
                  className='editor-canvas'
                />
              </div>
            )}

            <input name='newImage' type='file' onChange={this.handleNewImage.bind(this)}></input>

            <div className={'selections'}>
              <div className='sticker-select'>
                {[
                  'https://media.kubric.io/api/assetlib/6ef09a31-a1db-43db-b7d8-49ef9213b1a2.png',
                  'https://media.kubric.io/api/assetlib/f4d3bcab-6337-41d7-95b6-a4a81c5b1932.png',
                  'https://media.kubric.io/api/assetlib/64032b96-cc61-4c99-a195-87eab8fa964f.png',
                ].map((url, index) => (
                  <img
                    key={index}
                    src={url}
                    className={`sticker ${`type${index + 1}` === stickerType ? 'selected' : ''}`}
                    alt='sticker type'
                    onClick={() => {
                      this.setState({
                        stickerType: `type${index + 1}`,
                      });
                    }}
                  />
                ))}
              </div>

              <div className='select'>
                <select
                  defaultValue={'english'}
                  name='language'
                  ref={(ref) => {
                    this.langRef = ref;
                  }}>
                  <option value='english'>English</option>
                  <option value='hindi'>हिन्दी</option>
                  <option value='spanish'>español</option>
                  <option value='german'>Deutsche</option>
                  <option value='chinese'>中文</option>
                </select>
              </div>
            </div>

            {this.state.image && (
              <button
                className={'thebutton'}
                onClick={() => {
                  this.setState({ inProgress: true });
                  const image = this.editor.getImageScaledToCanvas().toDataURL();
                  var ref = firebase.storage().ref().child(`images/${uuid()}`);
                  const stickerLanguage = this.langRef.value;
                  ref.putString(image, 'data_url').then((snapshot) => {
                    snapshot.ref.getDownloadURL().then((downloadURL) => {
                      postData('https://genie.kubric.io/v1/reunite', {
                        profile_image: `https://storage.googleapis.com/modemagic-community.appspot.com/${snapshot.ref.fullPath}`,
                        sticker_language: stickerLanguage,
                        sticker_type: stickerType,
                      }).then(({ url }) => {
                        this.setState({
                          inProgress: false,
                          isDone: true,
                          outputImage: url,
                        });
                      });
                    });
                  });
                }}>
                Create
              </button>
            )}
          </div>
        )}

        {inProgress && (
          <div className='progress-container'>
            <h3>Powered by ModeMagic...</h3>
            <br />
            <div id='loading'></div>
          </div>
        )}
        {isDone && (
          <div className='done'>
            <img src={outputImage} className={'output'} />

            <div className={'share-buttons'}>
              <TwitterShareButton url={outputImage}>
                <TwitterIcon size={32} round={true} />
              </TwitterShareButton>
              <FacebookShareButton url={outputImage}>
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>
            </div>

            <a className={'modemagic'} href={'https://getmodemagic.com/'}>
              Get ModeMagic
            </a>
          </div>
        )}
      </>
    );
  }
}

export default App;
