const firebase = require('firebase');
require('firebase/database');
require('firebase/storage');

var firebaseConfig = {
  apiKey: 'AIzaSyC7GoIcBgOJimX_GrJ1AW10e2KQhBWQ8G8',
  authDomain: 'modemagic-community.firebaseapp.com',
  databaseURL: 'https://modemagic-community.firebaseio.com',
  projectId: 'modemagic-community',
  storageBucket: 'modemagic-community.appspot.com',
  messagingSenderId: '500478890679',
  appId: '1:500478890679:web:e9fe660088bd17c8c1f332',
  measurementId: 'G-YG0JGRVKHR',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
